.card {
    border: none;
    background-color: $primaryColorBright;
    color: $primaryColor;
    .card-header {
        border-bottom: 1px dashed $primaryColor;
        font-size: 1.2em;
        font-weight: bold;
        text-transform: uppercase;
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }
    .card-footer {
        background-color: $primaryColor;
        color: #ffffff;
    }
}