.sub-nav {
    .sub-nav-headline {
        font-size: 1.1em;
        font-weight: bold;
        color: $primaryColor;
        text-transform: uppercase;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            a {
                color: $textColor;
                &:hover, &:active, &.active {
                    color: $primaryColor;
                    text-decoration: none;
                }
            }
        }
    }
}