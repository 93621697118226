.content {
    &.background {
        background-color: $primaryColorSoft;
        padding-top: $elementPadding;
        padding-bottom: $elementPadding;
    }
    .headline-col {
        @include media-breakpoint-up(lg) {
            border-right: 1px solid $primaryColorLight;
            text-align: right;
        }
        .headline {
            font-size: 2em;
            color: $primaryColor;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
    }
    p {
        color: $textColor;
    }
    .icon-teaser {
        text-align: center;
        padding: 0 2rem;
        margin-bottom: 2rem;
        color: $primaryColor;
        &:hover, &:active {
            color: lighten($primaryColor, 15%);
            text-decoration: none;
        }
        img {
            display: inline-block;
        }
        .label {
            display: block;
            text-transform: uppercase;
            margin-top: 0.5rem;
        }
    }
    .content-text {
        padding-bottom: 2rem;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            color: $textColor;
            li {
                padding-left: 1.3em;
                text-indent: -1.5em;
                &::before {
                    content: '•';
                    font-size: 2em;
                    color: $primaryColor;
                    line-height: 0.9em;
                    vertical-align: bottom;
                    margin-right: 0.3em;
                }
            }
        }
        .subline {
            font-size: 1.1em;
            font-weight: bold;
            color: #005697;
            text-transform: uppercase;
        }
    }
}