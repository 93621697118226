body {
    min-height: 100%;
    background-image: url('../img/bg.png');
    background-repeat: no-repeat;
    background-position: center 2rem;
    overflow-x: hidden;
}

.container {
    max-width: 1200px;
    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }
}

.img-fluid {
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
}

.btn-secondary {
    background-color: $primaryColorSoft;
    border-color: $primaryColorLight;
    color: $primaryColor;
    padding-left: 36px;
    background-image: url('../img/icon-download.png');
    background-repeat: no-repeat;
    background-position: 12px center;
    &:hover, &:active {
        background-color: darken($primaryColorSoft, 5%);
        color: $primaryColor;
        border-color: $primaryColorLight;
    }
}

.google-maps {
    background-color: $primaryColorLight;
}