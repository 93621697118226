#accordion {
    .card {
        background-color: lighten($primaryColorBright, 5%);
    }
    .card-header {
        font-size: 1em;
        cursor: pointer;
        background-color: $primaryColorLight;
        &:hover {
            background-color: darken($primaryColorLight, 5%);
        }
        &.collapsed {
            background-color: $primaryColorBright;
        }
        &.collapsed:hover {
            background-color: darken($primaryColorBright, 5%);
        }
    }
}