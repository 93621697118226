footer {
    background-color: $primaryColorSoft;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding-top: $elementPadding;
    padding-bottom: $elementPadding;
    border-top: 1px solid $primaryColorLight;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(255,255,255,0), rgba($primaryColorLight, 0.4));
    }
    .headline-col {
        @include media-breakpoint-up(lg) {
            border-right: 1px solid $primaryColorLight;
            text-align: right;
        }
    }
    .headline {
        font-size: 1.1em;
        font-weight: bold;
        color: $primaryColor;
        text-transform: uppercase;
    }
    p {
        color: $textColor;
    }
    .footer-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: inline-block;
            padding: 0;
            a {
                color: $textColor;
                padding-right: 1rem;
                padding-left: 1rem;
                border-right: 1px solid $primaryColorLight;
                &:hover, &:active {
                    color: $primaryColor;
                    text-decoration: none;
                }
            }
            &:last-child a {
                padding-right: 0;
                border-right: 0;
            }
            @include media-breakpoint-down(md) {
                &:last-child a {
                    padding-right: 1rem;
                }
                &:first-child a {
                    padding-left: 0;
                }
            }
        }
    }
}