.header-navbar {
    padding: 0;
    @include media-breakpoint-down(md) {
        padding: 0 1rem;
    }
}

.main-navigation {
    @include media-breakpoint-up(lg) {
        margin-top: 5rem;
    }
    @include media-breakpoint-down(md){
        padding: 0px 1rem;
        background-color: #ffffff;
    }
    .nav-item {
        .nav-link {
            color: $textColor;
            padding-top: 1rem;
            padding-bottom: 1rem;
            line-height: 1rem;
            &:hover {
                color: $primaryColor;
            }
        }
        &.active .nav-link {
            color: $primaryColor;
        }
    }
    .nav-item.dropdown.show {
        background-color: #ffffff;
    }
    .dropdown-menu {
        padding: 0;
        margin: 0;
        border: none;
    }
    .dropdown-item {
        border-bottom: 1px solid #f0f0f0;
        padding: 0.3rem 1rem;
        color: $textColor;
        &:last-child {
            border-bottom: 0;
        }
        &.active, &:active {
            background-color: $primaryColorLight;
            color: $primaryColor;
        }
    }
    .navbar-nav .show > .nav-link {
        color: $textColor;
    }
    .navbar-nav .nav-link:hover {
        color: $primaryColor;
    }
}

.navbar-brand {
    padding: 0;
    margin: 1rem 0;
    position: relative;
    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 8rem;
        margin: 0;
    }
    &::after {
        content: '';
        position: absolute;
        top: 7px;
        height: 95px;
        right: 100%;
        width: 2000px;
        background-color: $primaryColorLight;
        margin-right: -50px;
        z-index: -1;
    }
}

.navbar-toggler {
    border: none;
    margin-bottom: auto;
    margin-top: 1rem;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='"+rgba($primaryColor,0.9)+"' stroke-width='4' stroke-miterlimit='10' d='M0 8h30 M0 15h30 M0 22h30'/%3E%3C/svg%3E");
}